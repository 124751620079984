.bg-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: column;
}

.bg-img {
    width: 90%;
    mix-blend-mode: hard-light;
    position: absolute;
    top: -150%;
}

.bg-image {
    width: 100%;
    height: 40vh;
    overflow: hidden;
    background-color: black;
    position: relative;
}

.white-bg {
    height: 80vh;
    width: 100%;
    background: #000;
}

.profile-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
    height: 80vh;
    width: 90%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border: 2px solid white;
}

.profile-pic-container {
    transform: translateY(-50px);
    width: 105px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
}

.profile-pic-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.Tracker {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background-color: #474747;
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    display: flex;
	justify-content: center;
	align-items: center;
    padding: 2rem;
    position: relative;
}

.close-button {
    position: absolute;
    top: -10px;
    right: 0;
    transform: translateY(-100%);
    font-size: 2.5rem;
    padding: 0.1em;
    border-radius: 4px;
    background-color: rgb(176, 176, 176);
    color: #000;
    display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.1s ease all;
	z-index: -1;
}

.close-button:hover {
    background-color: #e32525;
    color: white;
}

.formTitle {
    color: #e4e4e4;
    font-size: 1.7em;
    font-weight: 600;
    margin-bottom: 1rem;
}

.modal-form {
    width: 100%;
}

.modal-form label {
    font-size: 1.2em;
    color: #e4e4e4;
}

.modal-form input {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 0.5em;
    border: none;
    outline: none;
    color: black;
    background-color: rgb(143, 143, 143);
    font-size: 1em;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
}

.logout-button {
    padding: 0.7rem 1rem;
    color: white;
    background-color: #c0203e;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 1px;
    border: none;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}

.logout-button:hover, .modal-button:hover {
    background-color: #ff3d61;
}

.modal-button {
    display: inline-block;
    height: auto;
    width: auto;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    font-size: 1em;
    background-color: #c0203e;
    transition: all 0.2s;
}

.cancel {
    color: #000;
    background-color: #b1b1b6;
}

.cancel:hover {
    background-color: #ffffff;
}

.motion-div-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.motion-div {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.4rem 1.5rem;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.workout-actions {
    display: flex;
    justify-content: space-around;
    width: 20%;
}

.icon {
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    background-color: #ffffff;
    color: #000;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon:hover {
    color: #c0203e;
    background-color: white;
    scale: 1.05;
}