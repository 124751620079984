@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.notfound {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    justify-content: center;
    align-items: center;
    background: #333;
}

.notfound p {
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Oswald';
    letter-spacing: 2px;
}

.box {
    position: relative;
    height: 200px;
    transform-style: preserve-3d;
    animation: animate 16s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotateX(-20deg) rotateY(360deg);
    }
    100% {
        transform: rotateX(-20deg) rotateY(0deg);
    }
}

.box div {
    position: absolute;
    inset: 0;
    transform-style: preserve-3d;
}

.box div.block span {
    position: absolute;
    left: calc(50% - 100px);
    width: 200px;
    height: 100px;
    background: #444;
    transform: rotateY(calc(90deg * var(--i))) translateZ(100px);
    transition: 0.5s;
    cursor: pointer;
}

.box:hover div.block span {
    background: #ca0024;
    filter: drop-shadow(0 0 50px #ca0024);
}

.box div.text span {
    font-family: 'Oswald', serif;
    position: absolute;
    width: 100%;
    height: 100px;
    font-size: 8em;
    font-weight: 900;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-stroke: 3px black;
    transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
    line-height: 1em;
    z-index: 10;
    transform-style: preserve-3d;
}

.box div.text span:nth-child(4) {
    font-size: 6em;
}

.box div.text span::before {
    content: attr(data-text);
    position: absolute;
    -webkit-text-stroke: 0px black;
    bottom: 7px;
    transform-origin: bottom;
    transform: rotateX(-90deg);
    color: rgba(0, 0, 0, 0.1);
    filter: blur(5px);
}

.box div.text span::after {
    content: '';
    position: absolute;
    top: 100px;
    width: 360px;
    height: 60px;
    background: #444;
    transform: rotateX(90deg);
    transition: 0.5s;
}

.box:hover div.text span::after {
    background: #ca0024;
    filter: drop-shadow(0 0 50px #ca0024);
}