/*>>>>>>>>>>>>>>>>>>>>>>>>> Importing the fon 'INTER' <<<<<<<<<<<<<<<<<<<<*/
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

/*>>>>>>>>>>>>>>>>>>>>>>>>> Global Styles <<<<<<<<<<<<<<<<<<<<<<<<<<*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
}

body,
.home {
  width: 100%;
  height: 100vh;
  color: white;
  background-color: black;
}

a {
  text-decoration: none;
  color: white;
  text-align: center;
}

.navbarLogo {
  cursor: pointer;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>> BUTTON <<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.button {
  background-color: #ca0024;
  width: 350px;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  letter-spacing: 2px;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
}

.button:hover {
  scale: 1.02;
  font-size: 1.25em;
  background-color: #ff385c;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>> LANDING PAGE <<<<<<<<<<<<<<<<<<<<<<*/

.background-image{
  position: absolute;
  width: 100%;
  height: 832px;
  left: 0px;
  top: -55px;
  mix-blend-mode: hard-light;
}

.logo-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 70vh;
  background-color: transparent;
}

.logo-container img {
  width: 30vw;
  height: 12vh;
}

.text-container {
  width: 100%;
  position: absolute;
  top: 83vh;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.button-container {
  width: 100%;
  height: 55px;
  position: absolute;
  top: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>> LOGIN & SIGNUP PAGE <<<<<<<<<<<<<<<<<<<<<<*/
.login-page {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container h1 {
  font-size: 3em;
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  position: relative;
  gap: 3vh;
}

.inputBox input {
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #000000;
  border-radius: 5px;
  font-size: 1em;
  outline: none;
  color: #ffffff;
  transition: 0.5s;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 20px;
  pointer-events: none;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 700;
  letter-spacing: 0.2em;
}

.inputBox input:valid ~ span,
.inputBox input:focus-visible ~ span
{
  color: #000000;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background: #ffffff;
  border-radius: 2px;
}

.inputBox input:valid,
.inputBox input:focus
{
  border: 1px solid #ffffff;
}

form button {
  width: 50vh;
  border-radius: 25px;
  padding: 20px;
  height: fit-content;
}

.register {
  color: #ca0024;
  font-weight: 900;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.2s;
}

.register:hover {
  font-size: 1.1em;
  color: #ff385c;
  text-decoration: underline 2px solid #ff385c;
}

#signindiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}

.google:hover {
  scale: 1.02;
  background-color: white;
}

.login-image {
  display: flex;
  justify-content: flex-end;
  width: 45%;
  height: 100vh;
  overflow: hidden;
}

.login-image img {
  width: 100%;
  height: 130%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> HOME PAGE <<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.suggestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
  margin-top: 5vh;
}

.suggestion h2 {
  font-size: 2em;
  letter-spacing: 2px;
}

.suggestion h4 {
  font-size: 1.2em;
  letter-spacing: 2px;
}

.suggestion-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 20vh;
  width: 40%;
  padding: 20px;
  border-radius: 15px;
  line-height: 1.5;
  position: relative;
  border-top: 2px solid #ff385c;
  border-left: 2px solid #ff385c;
  cursor: pointer;
}

.suggestion-card img {
  position: absolute;
  left: -20px;
  top: -150%;
  width: 105%;
  filter: grayscale(0.7);
  mix-blend-mode: overlay;
}

.suggestion h1 {
  color: #fff;
  text-decoration: underline solid 1px;
  text-align: center;
  text-shadow: 0 0 15px #ca0024;
}

.workouts h1 {
  margin: 2vh 0 2vh 7%;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  z-index: 1;
  gap: 7vh;
}

.card {
  width: 280px;
  height: 400px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
  cursor: pointer;
  text-align: center;
}

.card:hover {
  scale: 1.05;
}

.card img {
  position: absolute;
  left: -20%;
  top: 0;
  width: 140%;
  filter: grayscale(0.5);
  mix-blend-mode: overlay;
}

.star {
  font-size: 1.5em;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NAVBAR <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
nav {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #00000000;
  transition: all 0.5s;
}

nav img {
  width: 20%;
}

.active {
  box-shadow: 10px 10px 20px #161616;
  background-color: #ca0024;
}

.dropbtn {
  transition: .2s ease-in-out;
  cursor: pointer;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>> FOOTER <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
footer {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

footer div img {
  width: 30%;
}

footer svg {
  cursor: pointer;
  transition: all 0.2s;
}

footer svg:hover {
  scale: 1.2;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>> GENDER PAGE <<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.gender-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.gender-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.male-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.female-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

#male,
#female {
  width: 50%;
  transition: 0.5s;
}

.femaleselcss {
  scale: 1.2;
  filter: drop-shadow(0px 1px 12px #d600ff);
}

.maleselcss {
  scale: 1.2;
  filter: drop-shadow(0px 1px 12px #4b00ff);
}

.maleunselcss {
  opacity: 50%;
}
.femaleunselcss {
  opacity: 50%;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  scale: 1;
  font-size: 1.2em;
  cursor: auto;
  background-color: #ca0025f5;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SOME EXTRA COMPONENTS <<<<<<<<<<<<<<<<<<<<<<*/
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.error {
  padding: 10px;
  background: #000;
  border: 1px solid #ca0024;
  color: #ca0024;
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid #ca0024;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> WORKOUT PAGE <<<<<<<<<<<<<<<<<<<<<<<*/
.workout-page div img {
  width: 90%;
  position: absolute;
  top: -110%;
  left: 5%;
  mix-blend-mode: hard-light;
  z-index: -1;
}

.workout-title {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.workout-page div h1 {
  font-size: 4em;
}

.exercise-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.exercise-container {
  margin-top: 2vh;
  display: flex;
  height: 8vh;
  width: 60%;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.exercise-container h3,
.exercise-container h4 {
  font-weight: 500;
}

.week-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 15vh;
  min-height: 100vh;
  width: 100%;
}

.week-bg-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  mix-blend-mode: hard-light;
}

.week-container {
  width: 80%;
  padding: 2vh;
  border: 3px dashed rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  display: flex;
  flex-wrap: wrap;
  gap: 5vh;
  justify-content: center;
  margin-bottom: 2vh;
}

.week-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 15vh;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
  cursor: pointer;
  font-size: 2em;
  width: 15vh;
}

.week-container div:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>> TERMS & CONDITIONS PAGE <<<<<<<<<<<<<<<<<<<<<<<*/
.terms-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-container {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  width: 70%;
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>> RESPONSIVE <<<<<<<<<<<<<<<<<<<<<<<*/
@media screen and (max-width: 600px) {

  /*>>>>>>>>>>>>>>>>>>>>>>>>>> LANDING PAGE <<<<<<<<<<<<<<<<<<<<<<<<*/
  .background-image{
    content: url("./assets/login\ mobile.png");
    height: 90vh;
  }

  .logo-container img {
    width: 70vw;
    height: 10vh;
  }

  .text-container {
    top: 82vh;
    font-size: 1em;
    font-weight: 500;
  }

  .text-container p {
    width: 87%;
  }

  .button-container {
    top: 91vh;
    width: 100%;
  }

  .button-container a {
    width: 80%;
  }

  /* button, form button {
    width: 80%;
    font-size: 0.9em;
    letter-spacing: 1px;
  } */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>> LOGIN & SIGNUP PAGE <<<<<<<<<<<<<<<<<<<<<<<<<<<*/
  .form-container {
    width: 100%;
    height: 80vh;
    margin-top: 10vh;
    justify-content: flex-start;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputBox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .inputBox input {
    width: 80%;
    padding: 15px;
    border-radius: 20px;
  }

  .inputBox input:valid ~ span,
  .inputBox input:focus-visible ~ span
  {
    transform: translateX(20px) translateY(-25px);
    font-size: 0.5em;
  }

  .inputBox span {
    left: 30px;
    font-size: 0.8em;
  }

  .form-container h1 {
    font-size: 2em;
  }

  .login-image {
    display: none;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NAVBAR <<<<<<<<<<<<<<<<<<<<<<<<*/
  nav {
    height: 6vh;
  }

  nav img {
    width: 50%;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> HOME PAGE <<<<<<<<<<<<<<<<<<<<<*/
  .suggestion {
    height: 20vh;
  }

  .suggestion h1 {
    font-size: 1.5em;
  }

  .suggestion-card {
    width: 80%;
    height: 10vh;
  }

  .suggestion h2 {
    font-size: 1em;
    letter-spacing: 1px;
  }
  
  .suggestion h4 {
    font-size: 1em;
    letter-spacing: 1px;
  }

  .workouts h1 {
    text-align: center;
    margin: 7vh 0 2vh 0;
    font-size: 1.5em;
  }

  .card-container {
    gap: 3vh;
  }

  .card {
    width: 80%;
    height: 10vh;
    padding: 10px;
    font-size: 0.8em;
  }

  .card img {
    top: -120%;
    width: 100%;
    left: 0;
    opacity: 0.5;
  }

  .card:nth-child(3) img, .card:nth-child(5) img {
    top: -180%;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> FOOTER <<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
  footer {
    height: 7vh;
    width: 95%;
  }

  footer div img {
    width: 70%;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> WORKOUT PAGE <<<<<<<<<<<<<<<<<<<<<<<*/
  .workout-page div img {
    content: url("./assets/login\ mobile\ 3.png");
    width: 100%;
    left: 0;
    top: -120%;
    mix-blend-mode: hard-light;
    opacity: 0.5;
  }

  .workout-page div h1 {
    font-size: 3em;
  }

  .exercise-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .exercise-container {
    width: 80%;
    backdrop-filter: blur(40px);
  }

  .exercise-container h3,
  .exercise-container h4 {
    font-size: 1em;
    font-weight: 400;
  }

  .workout-title {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GENDER PAGE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
  .gender-wrapper {
    height: 30vh;
  }
  
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-wrapper a {
    width: 80%;
  }

  #male,
  #female {
    width: 100%;
    transition: 0.5s;
  }
  
  .femaleselcss {
    scale: 1.2;
    filter: drop-shadow(0px 1px 12px #d600ff);
  }
  
  .maleselcss {
    scale: 1.2;
    filter: drop-shadow(0px 1px 12px #4b00ff);
  }
  
  .maleunselcss {
    opacity: 50%;
  }
  .femaleunselcss {
    opacity: 50%;
  }
  
  .disabled {
    opacity: 0.5;
  }
  
  .disabled:hover {
    scale: 1;
    font-size: 1.2em;
    cursor: auto;
    background-color: #ca0025f5;
  }
}